@import "./variables.css";

.SweepstakesPage {
    align-items: center;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

@media (max-width: 767px) {
    .SweepstakesPage {
        padding: 20px 10px;
    }
}

.SweepstakesPage-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.SweepstakesPage-divider {
    border: solid var(--highlight-color);
    border-width: 4px 0 0 0;
    box-sizing: border-box;
    margin: 0 auto 14px auto;
    width: 110px;
}

.SweepstakesPage-title {
    color: var(--primary-color);
    font-family: "adelle-condensed",serif;
    font-size: 3rem;
    font-weight: normal;
    margin: 0;
    max-width: 600px;
    text-align: center;
}

@media (max-width: 767px) {
    .SweepstakesPage-title {
        font-size: 2.4rem;
    }
}

.SweepstakesPage-description {
    color: var(--dark-color);
    font-family: "adrianna",sans-serif;
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 20px 0;
    max-width: 790px;
    text-align: center;
}

    .SweepstakesPage-description > a {
        font-weight: bold;
        color: #000000;
    }

.SweepstakesPage-preview {
    margin-bottom: 40px;
    max-width: 90vw;
}

.SweepstakesPage-download {
    background-color: var(--primary-color);
    border-radius: 5px;
    color: var(--lighter-color);
    display: inline-block;
    font-family: "adrianna",sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 30px;
    padding: 12px 60px;
    text-align: center;
    text-decoration: none;
}

.SweepstakesPage-rules {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 842px;
}

.SweepstakesPage-rules-description {
    color: var(--dark-color);
    font-family: "adrianna",sans-serif;
    font-size: 1.2rem;
    line-height: 1.58;
    margin: 0 0 10px 0;
    text-align: center;
}

.SweepstakesPage-rules-link {
    color: var(--dark-color);
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.SweepstakesPage-form {
    width: 310px;
}
    .SweepstakesPage-form label {
        text-transform: uppercase;
        font-weight: 600;
        font-family: Adrianna, Arial;
    }

    .SweepstakesPage-formElement--label_and_errors {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }


    .SweepstakesPage-formElement > input,
    .SweepstakesPage-formElement > select {
        box-sizing: border-box;
        width: 100%;
        height: 33px;
        border-radius: 5px;
        background-color: #ffffff;
        border: none;
        margin: 10px 0 20px 0;
        padding-left: 10px;
    }

    .SweepstakesPage-error {
        border: 1px solid var(--highlight-color) !important;
    }

        .SweepstakesPage-formElement > input.SweepstakesPage-error::placeholder {
            color: var(--highlight-color);
            opacity: 1.0;
        }

        .SweepstakesPage-formElement > select.SweepstakesPage-error {
            color: var(--highlight-color);
        }

    button.SweepstakesPage-form-submit_button {
        height: 44px;
        width: 148px;
        border-radius: 5px;
        color: #FFFFFF;
        background-color: var(--primary-color);
        font-size: 16px;
        font-weight: 600;
        line-height: 44px;
        border: none;
        outline: none;
        margin-left: 85px;
        margin-bottom: 50px;
        margin-top: 50px;
    }

.SweepstakesPage-enter-again {
    background-color: var(--primary-color);
    border-radius: 5px;
    color: var(--lighter-color);
    display: inline-block;
    font-family: "adrianna",sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 12px 40px;
    text-align: center;
    text-decoration: none;
    margin-bottom: 60px;
}